<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M12.3077 3L20.6154 7.15385V9H4V7.15385L12.3077 3ZM16 9.92308H19.6923V17.3077H16V9.92308ZM4 21V18.2308H20.6154V21H4ZM10.4615 9.92308H14.1538V17.3077H10.4615V9.92308ZM4.92308 9.92308H8.61538V17.3077H4.92308V9.92308ZM4.92308 19.1538V20.0769H19.6923V19.1538H4.92308ZM5.84615 10.8462V16.3846H7.69231V10.8462H5.84615ZM11.3846 10.8462V16.3846H13.2308V10.8462H11.3846ZM16.9231 10.8462V16.3846H18.7692V10.8462H16.9231ZM4.92308 8.07692H19.6923V7.70769L12.3077 3.99692L4.92308 7.70769V8.07692Z"
        fill="#2B2A29"/>
  </svg>
</template>

<script>
export default {
  name: "CarLogo"
}
</script>

<style scoped>

</style>