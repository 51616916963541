<template>
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="white">
    <path d="M15,3H9A3,3 0 0,0 6,6V10H7V6A2,2 0 0,1 9,4H15A2,2 0 0,1 17,6V19A2,2 0 0,1 15,21H9A2,2 0 0,1 7,19V15H6V19A3,3 0 0,0 9,22H15A3,3 0 0,0 18,19V6A3,3 0 0,
    0 15,3M3,12H13.25L10,8.75L10.66,8L15.16,12.5L10.66,17L10,16.25L13.25,13H3V12Z"/>
  </svg>
</template>
<script>
export default {
  name: 'LoginIcon'
}
</script>