<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M5.5 14C6.07633 13.9999 6.63499 14.1989 7.08145 14.5633C7.52791 14.9278 7.83473 15.4353 7.95 16H15V6H4C3.46957 6 2.96086 6.21071 2.58579 6.58579C2.21071 6.96086 2 7.46957 2 8V16H3.05C3.16527 15.4353 3.47209 14.9278 3.91855 14.5633C4.36501 14.1989 4.92367 13.9999 5.5 14ZM5.5 19C4.92367 19.0001 4.36501 18.8011 3.91855 18.4367C3.47209 18.0722 3.16527 17.5647 3.05 17H1V8C1 7.20435 1.31607 6.44129 1.87868 5.87868C2.44129 5.31607 3.20435 5 4 5H15C15.2652 5 15.5196 5.10536 15.7071 5.29289C15.8946 5.48043 16 5.73478 16 6V8H19L22 12V17H19.95C19.8352 17.5651 19.5286 18.0732 19.0821 18.4382C18.6356 18.8031 18.0767 19.0025 17.5 19.0025C16.9233 19.0025 16.3644 18.8031 15.9179 18.4382C15.4714 18.0732 15.1648 17.5651 15.05 17H7.95C7.83473 17.5647 7.52791 18.0722 7.08145 18.4367C6.63499 18.8011 6.07633 19.0001 5.5 19ZM5.5 15C5.10218 15 4.72064 15.158 4.43934 15.4393C4.15804 15.7206 4 16.1022 4 16.5C4 16.8978 4.15804 17.2794 4.43934 17.5607C4.72064 17.842 5.10218 18 5.5 18C5.89782 18 6.27936 17.842 6.56066 17.5607C6.84196 17.2794 7 16.8978 7 16.5C7 16.1022 6.84196 15.7206 6.56066 15.4393C6.27936 15.158 5.89782 15 5.5 15ZM17.5 14C18.0763 13.9999 18.635 14.1989 19.0815 14.5633C19.5279 14.9278 19.8347 15.4353 19.95 16H21V12.32L20.76 12H16V14.5C16.42 14.19 16.94 14 17.5 14ZM17.5 15C17.1022 15 16.7206 15.158 16.4393 15.4393C16.158 15.7206 16 16.1022 16 16.5C16 16.8978 16.158 17.2794 16.4393 17.5607C16.7206 17.842 17.1022 18 17.5 18C17.8978 18 18.2794 17.842 18.5607 17.5607C18.842 17.2794 19 16.8978 19 16.5C19 16.1022 18.842 15.7206 18.5607 15.4393C18.2794 15.158 17.8978 15 17.5 15ZM16 9V11H20L18.5 9H16Z"
        fill="#2B2A29"/>
  </svg>
</template>

<script>
export default {
  name: "KalLogo"
}
</script>

<style scoped>

</style>