import { render, staticRenderFns } from "./openVKBotMessage.vue?vue&type=template&id=61fa56a5&scoped=true"
import script from "./openVKBotMessage.vue?vue&type=script&lang=js"
export * from "./openVKBotMessage.vue?vue&type=script&lang=js"
import style0 from "./openVKBotMessage.vue?vue&type=style&index=0&id=61fa56a5&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61fa56a5",
  null
  
)

export default component.exports