<template>
  <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path fill="currentColor" d="M19 15C20.1 15 21 14.1 21 13V2C21 0.9 20.1 0 19 0H8.5C8.8 0.6 9 1.3 9 2H19V13H10V15M14 5V7H8V20H6V14H4V20H2V12H0.5V7C0.5 5.9 1.4 5 2.5 5H14ZM7 2C7 3.1 6.1 4 5 4C3.9 4 3 3.1 3 2C3 0.9 3.9 0 5 0C6.1 0 7 0.9 7 2ZM16 4H18V12H16V4ZM13 8H15V12H13V8ZM10 8H12V12H10V8Z"/>
  </svg>
</template>
<script>
export default {
  name: 'WarningInstructorIcon'
}
</script>
