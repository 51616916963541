<template>
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor" d="M14 8.5V4L11.89 5.06C12.0323 5.64847 12.0391 6.26155 11.91 6.85304C11.7809 7.44454 11.5192 7.99901 11.1446 8.47466C10.7701 8.95031 10.2925 9.33473 9.74773 9.59896C9.20301 9.86318 8.60542 10.0003 8 10C7.39458 10.0003 6.79699 9.86318 6.25227 9.59896C5.70755 9.33473 5.22991 8.95031 4.85536 8.47466C4.48081 7.99901 4.21911 7.44454 4.09 6.85304C3.96089 6.26155 3.96773 5.64847 4.11 5.06L1 3.5L8 0L15 3.5V8.5H14ZM8 7L6 6C6 7.1 6.9 8 8 8C9.1 8 10 7.1 10 6L8 7ZM10.75 3.42L8.16 2.1L5.47 3.47L8.07 4.79L10.75 3.42ZM8 11C10.67 11 16 12.33 16 15V18H0V15C0 12.33 5.33 11 8 11ZM8 12.9C5 12.9 1.9 14.36 1.9 15V16.1H14.1V15C14.1 14.36 10.97 12.9 8 12.9Z" />
  </svg>

</template>
<script>
export default {
  name: 'WarningStudentIcon'
}
</script>