<template>
  <div class="schedule-main-section">
    <div class="schedule-main-section_actions">
      <div class="d-flex width-full flex-wrap justify-space-between">
        <v-btn-toggle
            v-model="selectedLessonType"
            mandatory
            group
            color="black"
            class="flex-wrap"
        >
          <v-btn
              v-for="item in calendarButtons"
              :key="item.id"
              height="32"
              class="toggle-button"
              :value="item.id"
              @click="onToggleClick(item.id)"
          >
        <span :class="selectedLessonType === item.id ? 'white--text' : 'black--text'">
          {{ item.title }}
        </span>
          </v-btn>
        </v-btn-toggle>
        <div class="d-flex align-center flex-row flex-wrap">
          <span class="mr-3">Показать</span>
          <v-select
              no-data-text="Нет данных для отображения"
              style="max-width: 130px"
              height="32"
              v-model="type"
              :items="types"
              dense
              outlined
              hide-details
              class="select-period"
              :item-text="displayText"
              :item-value="valueText"
              @change="onToggleClick(lastSelectedJoinType)"
          />
        </div>
      </div>
      <div style="background-color: #4E7AEC; padding: 4px; gap: 10px; min-height: 27px; max-width: 523px"
           v-if="!isUserTeacher && lastSelectedJoinType === 1">
        <span style="font-weight: 600; font-size: 16px; line-height: 18.75px; color: #FEFEFE;  word-wrap: break-word;">Выберите инструктора, чтобы записаться на практики</span>
      </div>
      <div style="gap: 12px !important;" class="d-flex flex-column">
        <div class="d-flex flex-row flex-wrap" style="gap: 4px 8px !important;">
          <v-autocomplete v-model="selectedTeacher" class="select-user-template " outlined dense hide-details
                          height="41"
                          no-data-text="Нет данных для отображения"
                          item-value="id" @change="acceptEditableTeacher()" v-if="!isUserTeacher || isAdmin"
                          :item-text="item => `${item.surname || ''} ${item.name || ''} ${item.middleName || ''} `"
                          :items="listTeachers">
            <template #selection="{ item }">
              <div v-if="item.id">
                <span style="font-size: 16px; line-height: 18.75px; font-weight: 400; color: #2B2A29">
                  {{ item.surname + " " + item.name.charAt(0) + ". " + item.middleName.charAt(0) + "." }}
                </span>
                <span style="padding-left: 16px"></span>
                <br>
                <span style="font-size: 12px; line-height: 14px; font-weight: 400; color: #A6A8AA">{{
                    formatTransmissions(item.transmissionTypeEnum)
                  }}</span>
              </div>
              <div v-else>
                <span>Инструктор</span>
                <span style="padding-left: 16px"></span>
                <br>
                <span
                    style="font-size: 12px; line-height: 14px; font-weight: 400; color: #A6A8AA">Коробка передач
                </span>
              </div>
            </template>
            <template #item="{ item }">
              <div v-if="item.id">
                <span style="font-size: 16px; line-height: 18.75px; font-weight: 400; color: #2B2A29">
                  {{ item.surname + " " + item.name.charAt(0) + ". " + item.middleName.charAt(0) + "." }}
                </span>
                <br>
                <span style="font-size: 12px; line-height: 14px; font-weight: 400; color: #A6A8AA">
                  {{ formatTransmissions(item.transmissionTypeEnum) }}
                </span>
              </div>
              <div v-else>
                <span>Инструктор</span>
                <br>
                <span
                    style="font-size: 12px; line-height: 14px; font-weight: 400; color: #A6A8AA">Коробка передач
                </span>
              </div>
            </template>
          </v-autocomplete>
          <v-autocomplete 
              v-model="selectedStudent" 
              class="select-user-template" 
              outlined 
              dense 
              hide-details
              height="41"
              no-data-text="Нет данных для отображения"
              :items="[{id: 0, text: 'Студент не назначен'},...listStudents]"
              item-value="id" 
              @change="acceptEditableStudent()"
              v-if="isUserTeacher && lastSelectedJoinType === 1"
              :item-text="item => item.id === 0 ? 'text' : `${item.surname || ''} ${item.name || ''} ${item.middleName || ''} `"
          >
            <template #selection="{ item }">
              <div v-if="selectedStudent !== null">
                <div v-if="item.id">
                  <span style="font-size: 16px; line-height: 18.75px; font-weight: 400; color: #2B2A29">
                  {{ item.surname + " " + item.name.charAt(0) + ". " + item.middleName.charAt(0) + "." }}
                </span>
                  <span style="padding-left: 16px"></span>
                  <br>
                  <span style="font-size: 12px; line-height: 14px; font-weight: 400; color: #A6A8AA">
                  {{formatTransmissions(item.transmissionTypeEnum)}}
                </span>
                </div>
                <div v-else>
                  Студент не назначен
                </div>
              </div>
              <div v-else>
                <span>Студент</span>
                <span style="padding-left: 48px"></span>
                <br>
                <span style="font-size: 12px; line-height: 14px; font-weight: 400; color: #A6A8AA">
                  Коробка передач
                </span>
              </div>
            </template>
            <template #item="{ item }">
              <div>
              <span style="font-size: 16px; line-height: 18.75px; font-weight: 400; color: #2B2A29">
                {{ item.id === 0 ? item.text : item.id === null ? 'Студент' : item.surname + " " + item.name.charAt(0) + ". " + item.middleName.charAt(0) + "." }}
              </span>
                <br>
                <span v-if="item.id" style="font-size: 12px; line-height: 14px; font-weight: 400; color: #A6A8AA">
                  {{formatTransmissions(item.transmissionTypeEnum)}}
                </span>
                <span v-if="item.id === null" style="font-size: 12px; line-height: 14px; font-weight: 400; color: #A6A8AA">
                  Коробка передач
                </span>
              </div>
            </template>
          </v-autocomplete>
          <v-select v-model="selectedGroup" class="select-user-template " outlined dense hide-details
                    height="41"
                    no-data-text="Нет данных для отображения"
                    :items="listGroups"
                    item-value="groupId" @change="acceptLectureGroup()"
                    v-if="lastSelectedJoinType === 2">
            <template #item="{ item }">
              <div v-if="item.groupId">
              <span style="font-size: 16px; line-height: 18.75px; font-weight: 400; color: #2B2A29">
                {{ item.title }}
              </span>
              </div>
              <div v-else>
                <span>Группа</span>
              </div>
            </template>
            <template #selection="{ item }">
              <div v-if="item.groupId">
                <span style="font-size: 16px; line-height: 18.75px; font-weight: 400; color: #2B2A29">
                  {{ item.title }}
                </span>
              </div>
              <div v-else>
                <span>Группа</span>
              </div>
            </template>
          </v-select>
          <div class="d-flex flex-column" v-if="!isUserTeacher && lastSelectedJoinType === 1">
            <v-radio-group class="px-0 py-0 align-center ma-0 custom-radio" v-model="typePractices" hide-details
                           mandatory>
              <v-radio :value="false" @click="getPractices">
                <template v-slot:label>
                  <strong 
                    style="color:#2B2A29; font-weight: 400 !important; font-size: 16px !important; line-height: 18.75px !important;"
                  >
                    Показать свободные
                  </strong>
                </template>
              </v-radio>
              <v-radio :value="true" @click="getPractices">
                <template v-slot:label>
                  <strong
                      style="color:#2B2A29; font-weight: 400 !important; font-size: 16px !important; line-height: 18.75px !important;">Показать
                    занятые</strong>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
        </div>
        <div class="d-flex flex-row flex-wrap" v-if="isUserTeacher && lastSelectedJoinType === 1">
          <v-btn
              color="#4E7AEC"
              class="add-instructor-btn"
              @click="openNewPractice"
              :disabled="this.selectedTeacher === this.userID && isAdmin || this.selectedTeacher === null && isAdmin"
          >
            <section class="d-flex flex-row align-center" style="padding: 8px 12px 8px 12px !important;">
              <Icon color="white" icon="mdi-plus-circle-outline" height="24"/>
              <span class="add-instructor-text">Добавить практику</span>
            </section>
          </v-btn>
        </div>
      </div>
    </div>
    <div class="schedule-main-section_month_picker">
      <v-btn
          icon
          class="schedule-main-section_month_picker-btn"
          @click="previousMonth()"
      >
        <Icon icon="mdi-chevron-left" height="24"/>
      </v-btn>
      <v-toolbar-title v-if="test" :class=" isMobile ? 'month-name-mobile' : 'month-name'">
        {{ type === 'week' ? weekInMonth : month }}
      </v-toolbar-title>
      <v-btn
          icon
          class="schedule-main-section_month_picker-btn"
          @click="nextMonth()"
      >
        <Icon icon="mdi-chevron-right" height="24"/>
      </v-btn>
    </div>
    <div class="schedule-main-section_calendar">
      <v-calendar
          ref="calendar"
          v-model="value"
          :weekdays="weekday"
          :event-overlap-mode="mode"
          :type="type"
          :events="events"
          :event-color="getEventColor"
          @click:event="reviewEvent"
          :event-ripple="false"
          :event-height="eventHeight"
          :hide-header=false
          @click:more="viewDay"
          event-more
          event-more-text="+ {0}"
      >
        <template v-slot:event="{event}">
          <div
              class="calendar-event"
              :class="{
                'event-border': selectedLessonType === 1 && event.studentId === null && (event.practiceStateEnum === 0 || event.practiceStateEnum === 1),
                'px-[2px] justify-center' : isMobile, 
                'px-3' : !isMobile,
                'blur' : event.haaEnded
                }"
          >
            <div class="calendar-event_time" :class="showInfoSection ? 'font-16' : 'font-12'">
              {{ formatTime(event.startTime) }}
            </div>
            <div class="calendar-event_info" v-if="showInfoSection">
              <div class="calendar-event_info_type">{{ getEventTitle(event) }}</div>
              <div class="calendar-event_info_teacher">{{ getEventPersonName(event) }}</div>
            </div>
          </div>
        </template>
      </v-calendar>
    </div>
  </div>
</template>
<script>
import CarLogo from "@/components/logos/CarLogo.vue";
import LectureLogo from "@/components/logos/LectureLogo.vue";
import EventsRequest from "@/services/EventsRequest";
import moment from "moment/moment";
import {mapState} from "vuex";
import UsersRequest from "@/services/UsersRequest";
import GroupsRequest from "@/services/GroupsRequest";
import {formatTransmissions} from '@/utils/utils';
import {Icon} from '@iconify/vue2'

export default {
  components: {LectureLogo, CarLogo, Icon},
  mounted() {
    const buttonStyleReplace = [
      'v-btn',
      'v-btn--fab',
      'v-btn--has-bg',
      'v-btn--round',
      'theme--light',
      'v-size--small',
      'transparent',
    ]
    this.$refs.calendar.$el
        .querySelectorAll('.v-btn.v-btn--fab.v-btn--has-bg.v-btn--round.theme--light.v-size--small.primary')
        .forEach(item => {
          item.classList = '';
          buttonStyleReplace.forEach(x => {
            item.classList.toggle(x)
          })
        })
    this.test = true
    this.prevMonthAndYear = this.getMonthAndYear(this.value);
  },

  data: () => ({
    selectedLessonType: null,
    events: [],
    moreEvents: [],
    test: false,
    type: 'month',
    mode: 'stack',
    modes: ['column'],
    weekday: [1, 2, 3, 4, 5, 6, 0],
    value: moment().format('YYYY-MM-DD'),
    currentDate: moment(),
    lastSelectedJoinType: 1,
    selectedActiveUser: 0,
    types: [['month', 'месяц'], ['week', 'неделя'], ['day', 'день']],
    listTeachers: [{
      "id": null,
      "name": null,
      "surname": null,
      "middleName": null,
      "vkUserId": null,
      "email": null,
      "phoneNumber": null,
      "userName": null,
      "transmissionTypeEnum": null,
      "isAdmin": null,
      "city": null
    }],
    listStudents: [{
      "id": null,
      "name": null,
      "surname": null,
      "middleName": null,
      "vkUserId": null,
      "email": null,
      "phoneNumber": null,
      "userName": null,
      "groupId": null,
      "generalHours": null,
      "generalHoursSpent": null,
      "additinalHours": null,
      "additinalHoursSpent": null,
      "transmissionTypeEnum": null,
      "city": null
    }],
    selectedTeacher: null,
    selectedStudent: null,
    selectedGroup: null,
    listGroups: [{
      "groupId": null,
      "title": null,
      "groupNumber": null,
      "courseStartDate": null,
      "courseEndDate": null,
      "students": null,
    }],
    studentGeneralHours: '',
    studentGeneralHoursSpent: '',
    listInfoTeachers: [],
    typePractices: false,
  }),
  watch: {
    value(newValue) {
      this.confirmOnChangeMonthAndYear(newValue);
    },

    userID(newValue) {
      if (newValue !== null) {
        if (this.isUserTeacher && !this.isAdmin) {
          this.selectedTeacher = this.userID
          this.selectedActiveUser = this.userID
        }
      }
    },

    isUserStudent(newValue) {
      if (newValue) {
        this.getStudent()
      }
    },

    selectedTeacher(newValue) {
      // console.log(newValue)
    },

    currentDate(newValue){
      console.log(newValue)
    }
  },
  created() {
    this.getGroups().then(() => {
      if (this.isAdmin) {
        this.getAllStudents()
        this.getAllTeachers().then(() => {
          if (this.isUserTeacher && this.isAdmin) {
            this.selectedTeacher = this.listTeachers.length > 0 ? this.listTeachers[1].id : null;
            this.selectedActiveUser = this.listTeachers.length > 0 ? this.listTeachers[1].id : null;
          }
          if (this.isUserTeacher && !this.isAdmin) {
          }
          this.onToggleClick(this.lastSelectedJoinType)
        })
      } else if (this.isUserTeacher) {
        this.getAllTeachers()
        this.getCorrectStudentsForCurrentUser().then(() => {
          this.onToggleClick(this.lastSelectedJoinType)
        })
      } else {
        this.getGroupsStudent()
        this.getAllStudents()
        this.getAccessibleTeachers().then(() => {
          this.onToggleClick(this.lastSelectedJoinType)
        })
      }
    });
  },
  computed: {
    ...mapState(['user', 'isMobile']),
    showInfoSection() {
      return !(this.isMobile && (this.type === 'month' || this.type === 'week'))
    },
    eventHeight() {
      if (this.type === 'month') return 32
      if (this.type === 'week') return 32
      if (this.type === 'day') return 48
    },

    calendarButtons() {
      return [
        {
          id: 1,
          title: 'Практика',
        },
        {
          id: 2,
          title: 'Теория',
        },
      ]
    },
    month() {
      return this.$refs.calendar.title
    },
    weekInMonth() {
      return `${moment(this.$refs.calendar.lastStart.date).lang('ru').format('ll').slice(0, -7)}- ${moment(this.$refs.calendar.lastEnd.date).lang('ru').format('ll').slice(0, -8)}`
    },
    userID() {
      return this.user.userId
    },
    isUserTeacher() {
      return this.user.discriminator === 'Учитель'
    },
    isUserStudent() {
      return this.user.discriminator === 'Студент'
    },
    isAdmin() {
      return this.user.isAdmin
    },
  },
  methods: {
    formatTransmissions,

    async getCorrectStudents(id) {
      if (id) {
        const students = new UsersRequest()
        let listStudents
        await students.getCorrectStudentsForSelectedUser(id).catch(x => console.log(x)).then(x => {
          listStudents = x.data.students
        })
        this.listStudents = [{
          "id": null,
          "name": null,
          "surname": null,
          "middleName": null,
          "vkUserId": null,
          "email": null,
          "phoneNumber": null,
          "userName": null,
          "groupId": null,
          "generalHours": null,
          "generalHoursSpent": null,
          "additinalHours": null,
          "additinalHoursSpent": null,
          "transmissionTypeEnum": null,
          "city": null
        }]
        return this.listStudents.push(...listStudents)
      }
    },

    async acceptLectureGroup() {
      this.onToggleClick(this.lastSelectedJoinType)
    },

    async getStudent() {
      const student = new UsersRequest()
      const query = 'ShowDeleted=false'
      await student.getUsers(query).catch(x => console.log(x)).then((response) => {
        const users = response.data.students;
        const foundUser = users.find(user => user.id === this.userID);
        if (foundUser) {
          this.studentGeneralHours = foundUser.generalHours
          this.studentGeneralHoursSpent = foundUser.generalHoursSpent
        } else {
          this.studentGeneralHours = 0
          this.studentGeneralHoursSpent = 0
        }
      })
    },

    async openNewPractice() {
      let userName = ''
      let teacherTransmissions
      if (this.isAdmin) {
        const teacher = this.listTeachers.find(teacher => teacher.id === this.selectedTeacher)
        userName = teacher ? `${teacher.surname} ${teacher.name[0]}. ${teacher.middleName[0]}.` : '';
        teacherTransmissions = teacher ? teacher.transmissionTypeEnum : null
      } else {
        userName = `${this.user.surname} ${this.user.name[0]}. ${this.user.middleName[0]}.`
        const teacher = this.listTeachers.find(teacher => teacher.id === this.userID)
        teacherTransmissions = teacher ? teacher.transmissionTypeEnum : null
      }
      const listStudents = this.listStudents.filter(student => student.id !== null);
      const data = {
        listStudents: listStudents,
        userName: userName,
        userId: this.selectedActiveUser,
        isAdmin: this.isAdmin,
        teacherTransmissions: teacherTransmissions,
        practiceDate: this.type === 'day' ? moment(this.value).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
      }
      await this.$openNewPracticeDialogPlugin(data, true).then((isCancel) => {
        if (!isCancel) {
          this.onToggleClick(this.lastSelectedJoinType)
        }
      });
    },

    async getSelectedTeacherPractices() {
      const studentId = this.selectedStudent
      const practices = new EventsRequest()
      let cal
      if (this.type === 'week') {
        const monday = this.currentDate.clone().startOf('isoWeek').format('YYYY-MM-DD')
        const sunday = this.currentDate.clone().endOf('isoWeek').format('YYYY-MM-DD')
        const query = `Date=${monday}&Date2=${sunday}`
        await practices.getPracticeActiveUser(studentId, query).catch(x => console.log(x)).then(x => {
          cal = x.data.practice.map(event => ({
            ...event,
            start: new Date(event.startTime),
            end: new Date(event.endTime)
          }));
        })
        this.events = cal
      } else {
        const query = `Date=${this.value}`
        await practices.getPracticeActiveUser(studentId, query).catch(x => console.log(x)).then(x => {
          cal = x.data.practice.map(event => ({
            ...event,
            start: new Date(event.startTime),
            end: new Date(event.endTime)
          }));
        })
        this.events = cal
      }
    },

    async acceptEditableStudent() {
      if (this.isAdmin) {
        await this.getAccessibleTeachersForStudent(this.selectedStudent)
        this.onToggleClick(this.lastSelectedJoinType)
      } else {
        this.events = []
        await this.getSelectedTeacherPractices();
      }
    },

    async getAccessibleTeachersForStudent(id) {
      this.listTeachers = [{
        "id": null,
        "name": null,
        "surname": null,
        "middleName": null,
        "vkUserId": null,
        "email": null,
        "phoneNumber": null,
        "userName": null,
        "transmissionTypeEnum": null,
        "isAdmin": null,
        "city": null
      }]

      if (!id) {
        return this.getAllTeachers()
      }
      const teachers = new UsersRequest()
      let listTeachers
      await teachers.getCorrectTeacherForSelectedUser(id).catch(x => console.log(x)).then(x => {
        listTeachers = x.data.activeUsers
      })
      return this.listTeachers.push(...listTeachers)
    },

    async acceptEditableTeacher() {
      if (!this.selectedTeacher) {
        return this.onToggleClick(this.lastSelectedJoinType)
      }
      const selectedId = this.selectedTeacher;
      if (this.isUserTeacher && this.isAdmin) {
        this.events = []
        this.selectedActiveUser = selectedId
        await this.getCorrectStudents(this.selectedActiveUser)
        this.onToggleClick(this.lastSelectedJoinType)
      } else {
        if (this.lastSelectedJoinType === 1) {
          const assignedPractices = await this.getPracticeStudent()
          const practices = await this.getFreePractices(selectedId);
          this.events = [...assignedPractices, ...practices];
          this.selectedActiveUser = selectedId
        } else {
          this.onToggleClick(this.lastSelectedJoinType)
        }
      }
    },

    onToggleClick(id) {
      this.lastSelectedJoinType = id;
      switch (id) {
        case 1:
          return this.testPractices()
        case 2:
          return this.getLessons()
      }
    },

    async reviewEvent(e) {
      if (e.event.lectureType) {
        const student = this.listStudents.find(student => !!e.event.studentId && student.id === e.event.studentId);
        const studentName = student ? `${student.surname} ${student.name[0]}. ${student.middleName[0]}.` : ''
        const data = {
          teacher: e.event.activeUserFullNameShort,
          student: studentName,
          e: e,
        }
        await this.$reviewLectureDialogPlugin(data).then((isCancel) => {
          if (!isCancel) this.onToggleClick(this.lastSelectedJoinType)
        })
      } else {
        const listStudents = this.listStudents.filter(student => student.id !== null);
        const student = listStudents.find(student => student.id === e.event.studentId);
        let studentName, studentGeneralHours, studentGeneralHoursSpent, studentAdditionalHoursSpent
        if (this.isUserStudent && student) {
          studentName = student ? `${student.surname} ${student.name[0]}. ${student.middleName[0]}.` : ''
          studentGeneralHours = student.generalHours
          studentGeneralHoursSpent = student.generalHoursSpent
          studentAdditionalHoursSpent = student.additinalHoursSpent
        } else if (this.isUserStudent && !student) {
          const student = listStudents.find(student => student.id === this.userID);
          studentName = student ? `${student.surname} ${student.name[0]}. ${student.middleName[0]}.` : ''
          studentGeneralHours = student.generalHours
          studentGeneralHoursSpent = student.generalHoursSpent
          studentAdditionalHoursSpent = student.additinalHoursSpent
        } else {
          studentName = student ? `${student.surname} ${student.name[0]}. ${student.middleName[0]}.` : ''
        }
        const teacher = this.listTeachers.find(teacher => this.selectedTeacher && teacher.id === this.selectedTeacher)
        const teacherTransmissions = teacher ? teacher.transmissionTypeEnum : [];
        const data = {
          typePractices: this.typePractices,
          e: e,
          listStudents: listStudents,
          userTeacher: this.isUserTeacher,
          userIsStudentInPractice: this.userID === e.event.studentId,
          isAdmin: this.isAdmin,
          userId: this.userID,
          student: this.isUserStudent,
          teacherTransmissions: teacherTransmissions,
          studentGeneralHours: studentGeneralHours,
          studentGeneralHoursSpent: studentGeneralHoursSpent,
          studentAdditionalHoursSpent: studentAdditionalHoursSpent,
          studentName: e.event.studentFullName,
          studentPhone: e.event.phoneNumberStudent,
          teacherPhone: e.event.phoneNumberActiveUser,
          teacherName: e.event.activeUserFullName
        }
        await this.$reviewPracticeDialogPlugin(data).then((isCancel) => {
          if (!isCancel) this.onToggleClick(this.lastSelectedJoinType)
        })
      }
    },

    async getLessonsAdmin() {
      const lessons = new EventsRequest()
      let lessonsData
      let query
      if (this.selectedTeacher) {
        this.selectedGroup ? query = `GroupId=${this.selectedGroup}&Date=${this.value}` : query = `Date=${this.value}`
        const userId = this.selectedTeacher
        await lessons.getLectureActiveUser(userId, query).catch(x => console.log(x)).then(x => {
          lessonsData = x.data.lecture.map(event => ({
            ...event,
            start: new Date(event.startTime),
            end: new Date(event.endTime)
          }));
        })
      } else if (this.selectedGroup) {
        const lessons = new EventsRequest()
        let lessonsData = []
        const monthTime = `Date=${this.value}`
        await lessons.getLectureGroupId(this.selectedGroup, monthTime).catch(x => console.log(x)).then(x => {
          lessonsData = x.data.lecture.map(event => ({
            ...event,
            start: new Date(event.startTime),
            end: new Date(event.endTime)
          }));
        })
        return this.events = lessonsData
      }
      return lessonsData
    },

    async getFreePractices(userId) {
      const practices = new EventsRequest()
      let cal
      if (this.type === 'week') {
        const monday = this.currentDate.clone().startOf('isoWeek').format('YYYY-MM-DD')
        const sunday = this.currentDate.clone().endOf('isoWeek').format('YYYY-MM-DD')
        const query = `Id=${userId}&Date=${monday}&Date2=${sunday}&showAssigned=${this.typePractices}`
        await practices.getFreePracticeActiveUser(query).catch(x => console.log(x)).then(x => {
          cal = x.data.practice.map(event => ({
            ...event,
            start: new Date(event.startTime),
            end: new Date(event.endTime)
          }));
        })
      } else {
        const query = `Id=${userId}&Date=${this.value}&showAssigned=${this.typePractices}`
        await practices.getFreePracticeActiveUser(query).catch(x => console.log(x)).then(x => {
          cal = x.data.practice.map(event => ({
            ...event,
            start: new Date(event.startTime),
            end: new Date(event.endTime)
          }));
        })
      }
      return cal
    },

    async getPracticesAdmin() {
      const practices = new EventsRequest()
      let cal, query
      const selectedTeacher = this.selectedTeacher
      const selectedStudent = this.selectedStudent
      if (this.type === 'week') {
        const monday = this.currentDate.clone().startOf('isoWeek').format('YYYY-MM-DD')
        const sunday = this.currentDate.clone().endOf('isoWeek').format('YYYY-MM-DD')
        if (selectedTeacher) {
          query = `Date=${monday}&Date2=${sunday}&ActiveUserId=${selectedTeacher}`
        } else {
          query = `Date=${monday}&Date2=${sunday}`
        }
        await practices.getPracticeActiveUser(selectedStudent, query).catch(x => console.log(x)).then(x => {
          cal = x.data.practice.map(event => ({
            ...event,
            start: new Date(event.startTime),
            end: new Date(event.endTime)
          }));
        })
      } else {
        if (selectedTeacher) {
          query = `Date=${this.value}&ActiveUserId=${selectedTeacher}`
        } else {
          query = query = `Date=${this.value}`
        }
        await practices.getPracticeActiveUser(selectedStudent, query).catch(x => console.log(x)).then(x => {
          cal = x.data.practice.map(event => ({
            ...event,
            start: new Date(event.startTime),
            end: new Date(event.endTime)
          }));
        })
      }
      return cal
    },

    async getPracticeStudent() {
      const practices = new EventsRequest()
      let cal
      if (this.type === 'week') {
        const monday = this.currentDate.clone().startOf('isoWeek').format('YYYY-MM-DD')
        const sunday = this.currentDate.clone().endOf('isoWeek').format('YYYY-MM-DD')
        const query = `Date=${monday}&Date2=${sunday}`
        await practices.getOnlyAssigned(query).catch(x => console.log(x)).then(x => {
          cal = x.data.practice.map(event => ({
            ...event,
            start: new Date(event.startTime),
            end: new Date(event.endTime)
          }));
        })
      } else {
        const query = `Date=${this.value}`
        await practices.getOnlyAssigned(query).catch(x => console.log(x)).then(x => {
          cal = x.data.practice.map(event => ({
            ...event,
            start: new Date(event.startTime),
            end: new Date(event.endTime)
          }));
        })
      }
      return cal
    },

    async getLessonsStudent() {
      const lessons = new EventsRequest()
      let lessonsData = []
      const activeUserId = this.selectedTeacher
      const groupId = this.selectedGroup !== null ? this.selectedGroup : this.$store.state.user.groupId
      if (activeUserId && groupId) {
        const query = `GroupId=${groupId}&Date=${this.value}`
        await lessons.getLectureActiveUser(activeUserId, query).catch(x => console.log(x)).then(x => {
          lessonsData = x.data.lecture.map(event => ({
            ...event,
            start: new Date(event.startTime),
            end: new Date(event.endTime)
          }));
        })
      } else if (this.selectedGroup !== null) {
        const query = `GroupId=${groupId}&Date=${this.value}`
        await lessons.getLectureGroupId(this.selectedGroup, query).catch(x => console.log(x)).then(x => {
          lessonsData = x.data.lecture.map(event => ({
            ...event,
            start: new Date(event.startTime),
            end: new Date(event.endTime)
          }));
        })
      } else if (this.$store.state.user.groupId) {
        const query = `Date=${this.value}`
        await lessons.getLessons(query).catch(x => console.log(x)).then(x => {
          lessonsData = x.data.lecture.map(event => ({
            ...event,
            start: new Date(event.startTime),
            end: new Date(event.endTime)
          }));
        })
      }
      return lessonsData
    },

    async getPracticesTeacher() {
      const practices = new EventsRequest()
      let practicesData
      if (this.selectedStudent) {
        if (this.type === 'week') {
          const monday = this.currentDate.clone().startOf('isoWeek').format('YYYY-MM-DD')
          const sunday = this.currentDate.clone().endOf('isoWeek').format('YYYY-MM-DD')
          const query = `Date=${monday}&Date2=${sunday}&StudentId=${this.selectedStudent}`
          await practices.getPractices(query).catch(x => console.log(x)).then(x => {
            practicesData = x.data.practice.map(event => ({
              ...event,
              start: new Date(event.startTime),
              end: new Date(event.endTime)
            }));
          })
        } else {
          const query = `Date=${this.value}&StudentId=${this.selectedStudent}`
          await practices.getPractices(query).catch(x => console.log(x)).then(x => {
            practicesData = x.data.practice.map(event => ({
              ...event,
              start: new Date(event.startTime),
              end: new Date(event.endTime)
            }));
          })
        }
      } else {
        if (this.type === 'week') {
          const monday = this.currentDate.clone().startOf('isoWeek').format('YYYY-MM-DD')
          const sunday = this.currentDate.clone().endOf('isoWeek').format('YYYY-MM-DD')
          const query = `Date=${monday}&Date2=${sunday}`
          await practices.getPractices(query).catch(x => console.log(x)).then(x => {
            practicesData = x.data.practice.map(event => ({
              ...event,
              start: new Date(event.startTime),
              end: new Date(event.endTime)
            }));
          })
        } else {
          const query = `Date=${this.value}`
          await practices.getPractices(query).catch(x => console.log(x)).then(x => {
            practicesData = x.data.practice.map(event => ({
              ...event,
              start: new Date(event.startTime),
              end: new Date(event.endTime)
            }));
          })
        }
      }
      return practicesData
    },

    async getLessonsTeacher() {
      const lessons = new EventsRequest()
      let lessonsData
      let query
      if (this.type === 'week') {
        const monday = this.currentDate.clone().startOf('isoWeek').format('YYYY-MM-DD')
        const sunday = this.currentDate.clone().endOf('isoWeek').format('YYYY-MM-DD')
        this.selectedGroup ? query = `GroupId=${this.selectedGroup}&Date=${monday}&Date2=${sunday}` : query = `Date=${monday}&Date2=${sunday}`
        await lessons.getLectureCurrentUser(query).catch(x => console.log(x)).then(x => {
          lessonsData = x.data.lecture.map(event => ({
            ...event,
            start: new Date(event.startTime),
            end: new Date(event.endTime)
          }));
        })
      } else {
        this.selectedGroup ? query = `GroupId=${this.selectedGroup}&Date=${this.value}` : query = `Date=${this.value}`
        await lessons.getLectureCurrentUser(query).catch(x => console.log(x)).then(x => {
          lessonsData = x.data.lecture.map(event => ({
            ...event,
            start: new Date(event.startTime),
            end: new Date(event.endTime)
          }));
        })
      }
      return lessonsData
    },

    async getLessons() {
      if (this.isAdmin) {
        this.events = await this.getLessonsAdmin();
      } else if (this.isUserTeacher && !this.isAdmin) {
        this.events = await this.getLessonsTeacher();
      } else {
        this.events = await this.getLessonsStudent();
      }
    },

    async getPractices() {
      const practices = new EventsRequest()
      let practicesData
      const userId = this.selectedTeacher
      if (userId) {
        if (this.type === 'week') {
          const monday = this.currentDate.clone().startOf('isoWeek').format('YYYY-MM-DD')
          const sunday = this.currentDate.clone().endOf('isoWeek').format('YYYY-MM-DD')
          const query = `Id=${userId}&Date=${monday}&Date2=${sunday}&showAssigned=${this.typePractices}`
          await practices.getFreePracticeActiveUser(query).catch(x => console.log(x)).then(x => {
            practicesData = x.data.practice.map(event => ({
              ...event,
              start: new Date(event.startTime),
              end: new Date(event.endTime)
            }));
          })
        } else {
          const query = `Id=${userId}&Date=${this.value}&showAssigned=${this.typePractices}`
          await practices.getFreePracticeActiveUser(query).catch(x => console.log(x)).then(x => {
            practicesData = x.data.practice.map(event => ({
              ...event,
              start: new Date(event.startTime),
              end: new Date(event.endTime)
            }));
          })
        }

        this.events = []
        const assignedPractices = await this.getPracticeStudent()
        this.events = [...assignedPractices, ...practicesData];
        return this.events
      } else return null
    },

    getEventPersonName(e) {
      if (this.selectedLessonType === 2) {
        if (e.activeUserFullNameShort) return e.activeUserFullNameShort
        else return `Преп. не назначен`
      } else {
        if (this.isUserStudent) {
          if (e.activeUserFullNameShort) return e.activeUserFullNameShort
          else return `Преп. не назначен`
        }
        if (this.isUserTeacher || this.isAdmin) {
          if (e.studentFullNameShort) return e.studentFullNameShort
          else return `Студ. не назначен`
        }
      }
    },

    getEventTitle(e) {
      if (this.selectedLessonType === 2) {
        if (e.lectureType === 1) return `Основы вождения`
        if (e.lectureType === 2) return `ПДД`
        if (e.lectureType === 3) return `Медицина`
        if (e.lectureType === 4) return `Другое`
      } else {
        return 'Вождение'
      }
    },

    async testPractices() {
      if (!this.selectedTeacher && !this.selectedStudent && this.isAdmin) {
        return this.events = []
      }
      if (this.isAdmin) {
        this.events = await this.getPracticesAdmin();
      } else if (this.isUserTeacher && !this.isAdmin) {
        this.events = await this.getPracticesTeacher();
      } else {
        if (!this.selectedTeacher) {
          this.events = await this.getPracticeStudent();
        } else {
          const assignedPractices = await this.getPracticeStudent()
          const practices = await this.getFreePractices(this.selectedTeacher);
          this.events = [...assignedPractices, ...practices];
        }
      }
    },

    async getCorrectStudentsForCurrentUser() {
      const teachers = new UsersRequest();
      let students
      await teachers.getCorrectStudentsForCurrentUser().catch(x => console.log(x)).then(x => {
        students = x.data.students
      })
      this.listStudents.push(...students)
    },

    async getAccessibleTeachers() {
      const teachers = new UsersRequest();
      let activeUsers
      await teachers.getAccessibleTeacher().catch(x => console.log(x)).then(x => {
        activeUsers = x.data.activeUsers
      })
      this.listTeachers.push(...activeUsers)
    },

    async getAllTeachers() {
      const teachers = new UsersRequest();
      let activeUsers
      await teachers.getActiveUser().catch(x => console.log(x)).then(x => {
        activeUsers = x.data.activeUsers
      })
      this.listInfoTeachers.push(...activeUsers)
      this.listTeachers.push(...activeUsers)
    },

    async getAllStudents() {
      this.listStudents = [{
        "id": null,
        "name": null,
        "surname": null,
        "middleName": null,
        "vkUserId": null,
        "email": null,
        "phoneNumber": null,
        "userName": null,
        "groupId": null,
        "generalHours": null,
        "generalHoursSpent": null,
        "additinalHours": null,
        "additinalHoursSpent": null,
        "transmissionTypeEnum": null,
        "city": null
      }]

      const student = new UsersRequest()
      let studentList
      const query = 'ShowDeleted=false'
      await student.getUsers(query).catch(x => console.log(x)).then(x => {
        studentList = x.data.students
      })
      this.listStudents.push(...studentList)
    },

    async getGroups() {
      const groups = new GroupsRequest()
      let groupList
      await groups.getGroups().catch(x => console.log(x)).then(x => {
        groupList = x.data
      })
      return this.listGroups.push(...groupList)
    },

    async getGroupsStudent() {
      this.listGroups = [{
        "groupId": null,
        "title": null,
        "groupNumber": null,
        "courseStartDate": null,
        "courseEndDate": null,
        "students": null,
      }]
      const groups = new GroupsRequest()
      let groupList
      await groups.getGroupStudent().catch(x => console.log(x)).then(x => {
        groupList = x.data
      })
      this.selectedGroup = groupList[0].groupId
      return this.listGroups.push(...groupList)
    },

    formatTime(startTime) {
      const date = new Date(startTime);
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    },

    getEventColor(event) {
      if (this.selectedLessonType === 2) {
        if (event.lectureType === 1) return `#8CED7C`
        if (event.lectureType === 2) return `#FFCD6D`
        if (event.lectureType === 3) return `#FC7DC9`
        if (event.lectureType === 4) return `#d6dae1`
      } else {
        if (event.studentId !== null && (event.practiceStateEnum === 0 || event.practiceStateEnum === 1))
          return '#9DB9FF';
        else if (event.studentId === null && (event.practiceStateEnum === 0 || event.practiceStateEnum === 1))
          return '#FFFFFF';
        else if (event.practiceStateEnum === 2)
          return '#fb8689'
        else
          return 'rgba(11, 11, 11, 0.04)';
      }
    },

    async previousMonth() {
      this.$refs.calendar.prev()
      if (this.type === 'week') {
        this.currentDate = this.currentDate.clone().subtract(1, 'week');
        await this.onToggleClick(this.lastSelectedJoinType)
      }
      if (this.type === 'month')
      {
        this.currentDate = moment(this.value)
      }
      if(this.type === 'day'){
        this.currentDate = this.currentDate.clone().subtract(1, 'day');
      }
    },

    async nextMonth() {
      this.$refs.calendar.next()
      if (this.type === 'week') {
        this.currentDate = this.currentDate.clone().add(1, 'week');
        await this.onToggleClick(this.lastSelectedJoinType)
      }
      if (this.type === 'month')
      {
        this.currentDate = moment(this.value)
      }
      if(this.type === 'day'){
        this.currentDate = this.currentDate.clone().add(1, 'day');
      }
    },

    async confirmOnChangeMonthAndYear(newValue) {
      if (this.type !== 'week') {
        const currentMonthAndYear = this.getMonthAndYear(newValue);
        if (currentMonthAndYear !== this.prevMonthAndYear) {
          this.onToggleClick(this.lastSelectedJoinType);
          this.prevMonthAndYear = currentMonthAndYear;
        }
      }
    },

    getMonthAndYear(dateString) {
      const [year, month] = dateString.split('-');
      return `${year}-${month}`;
    },

    viewDay({date}) {
      this.value = date
      this.type = 'day'
    },

    displayText(item) {
      return item[1];
    },

    valueText(item) {
      return item[0];
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/monthScheduleStyles.css";

.select-user-template {
  max-width: 312px !important;
  border-radius: 12px !important;

  .v-input__slot {
    display: flex !important;
    align-items: center !important;
    min-height: 41px !important;
  }

  .v-input__prepend-inner {
    margin: 0 !important;
  }

  .v-input__icon {
    max-height: 41px !important;
  }

  .v-input__control {
    max-height: 41px !important;

    .v-input__slot {
      max-height: 41px !important;
    }
  }

  .v-select__selection--comma {
    margin: 0 !important;
  }
}

.schedule-main-section {
  width: 100%;
  height: 100%;
  padding: 0 12px 12px 12px;

  &_actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 12px !important;
    flex-wrap: wrap;
    gap: 8px !important;
  }

  &_month_picker {
    height: 54px;
    display: flex;
    width: 100%;
    justify-content: center;

    &-btn {
      margin: 0;
      align-self: center;
    }
  }

  &_calendar {
    height: 100%;
    width: 100%;
  }
}

.toggle-button {
  margin-right: 0 !important;
  margin-left: 0 !important;
  color: black !important;
  border-radius: 4px !important;
  text-transform: none !important;
  padding: 0 16px !important;
}

.v-btn--active::before {
  opacity: 1 !important;
}

.v-btn:hover,
.v-btn:focus,
{
  color: black !important;
}

.month-name {
  font-size: 24px !important;
  font-weight: 700 !important;
  display: flex !important;
  align-items: center !important;
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.month-name-mobile {
  font-size: 24px !important;
  font-weight: 700 !important;
  display: flex !important;
  align-items: center !important;
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.event-time {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: black !important;
}

.event-type {
  font-weight: 600 !important;
  font-size: 12px !important;
  color: black !important;
}

.event-instructor,
.event-long {
  font-weight: 400 !important;
  font-size: 12px !important;
  color: black !important;
}

.custom-radio .v-radio {
  margin-bottom: 0 !important;
}

.custom-radio .v-input--selection-controls__ripple {
  height: 0;
}

.custom-radio [class*="__ripple"] {
  height: 0 !important;
}

.close-button {
  border-radius: 12px !important;
  background-color: #4E7AEC !important;
  height: 32px !important;
  width: 89px !important;
  text-transform: none !important;
  margin-bottom: 12px !important;
  margin-right: 12px !important;

  &__text {
    text-transform: none !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 18.75px !important;
  }
}

.v-text-field-custom {
  width: 367px !important;
  height: 32px !important;
  margin-bottom: 24px !important;
  margin-top: 24px !important;
}

.v-text-field--outlined, {
  border-radius: 12px !important;
}

.flex-row {
  display: flex;
  align-items: center;
}

.select-instructor-template {
  .v-input__control {
    max-height: 32px !important;

    .v-input__slot {
      max-height: 32px !important;
      display: flex !important;
      align-items: center !important;
      min-height: 32px !important;
    }
  }

  .v-input__prepend-inner {
    margin: 0 !important;
  }

  .v-input__icon {
    max-height: 32px !important;
  }
}

.blur {
  filter: opacity(30%)
}

.calendar-event {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  color: #2B2A29;
  border-radius: 4px;
  gap: 12px;

  &_time {
    display: flex;
    align-items: center;
    font-weight: 600;
    line-height: 20px;
  }

  &_info {
    display: flex;
    flex-direction: column;
    height: 28px;
    overflow: hidden;

    &_type {
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
    }

    &_teacher {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
    }
  }
}

.event-border {
  border: 1px solid #4E7AEC;
  border-radius: 4px;
}

.font-16 {
  font-size: 16px;
}

.font-12 {
  font-size: 12px;
}
</style>